import(/* webpackMode: "eager" */ "/home/tlicho/components/atoms/article/text.tsx");
;
import(/* webpackMode: "eager" */ "/home/tlicho/components/atoms/article/title.tsx");
;
import(/* webpackMode: "eager" */ "/home/tlicho/components/atoms/contact.tsx");
;
import(/* webpackMode: "eager" */ "/home/tlicho/components/molecules/box.tsx");
;
import(/* webpackMode: "eager" */ "/home/tlicho/components/molecules/forms/contact.tsx");
;
import(/* webpackMode: "eager" */ "/home/tlicho/components/molecules/forms/suggestion.tsx");
;
import(/* webpackMode: "eager" */ "/home/tlicho/node_modules/@mui/material/Container/index.js");
;
import(/* webpackMode: "eager" */ "/home/tlicho/node_modules/@mui/material/Grid/index.js");
;
import(/* webpackMode: "eager" */ "/home/tlicho/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/tlicho/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/tlicho/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");

'use client'

import { useTheme } from '@mui/material/styles'
import { Typography } from '@mui/material'

// Types
import type { TitleProps } from '@/types/components/atoms/article/title'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

const TitleAtom = (props: TitleProps) => {
	// Props
	const { title } = props

	// Variables
	const { t } = useTranslation()
	const theme = useTheme()

	return (
		<Typography variant="h1" pb={{ xs: 4, smmd: 7 }} fontSize={{ xs: 28, smmd: 42 }} fontWeight={700} color="primary.dark">
			{t(title)}
		</Typography>
	)
}

export default TitleAtom

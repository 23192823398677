'use client'

import dynamic from 'next/dynamic'
import { useColorScheme, useTheme, alpha } from '@mui/material/styles'
import { useMediaQuery, Box } from '@mui/material'
import { blue, blueGrey, lightBlue, pink, lime, brown, amber, grey } from '@mui/material/colors'

// Types
import type { TextProps } from '@/types/components/atoms/article/text'

// Componenets
const TransAtom = dynamic(() => import('@/components/atoms/article/trans'))

const TextAtom = (props: TextProps) => {
	// Props
	const { children } = props

	// Variables
	const theme = useTheme()

	// Mode
	const { mode } = useColorScheme()
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
	const themeMode = mode == 'system' ? (prefersDarkMode ? 'dark' : 'light') : mode

	return (
		<Box
			sx={{
				p: 0,
				'& img': { maxWidth: '100%' },
				'& pre::-webkit-scrollbar': {
					width: 10,
					bgcolor: 'transparent'
				},
				' &pre::-webkit-scrollbar-thumb': { bgcolor: grey[600] },
				'& .ltr': { textAlign: theme.direction === 'rtl' ? 'right' : 'left' },
				'& .rtl': { textAlign: theme.direction === 'rtl' ? 'left' : 'right' },
				'& .editor-paragraph': {
					position: 'relative',
					fontWeight: 400,
					fontHeight: 1.21,
					m: 0,
					mb: 1,
					'&:last-child': { mb: 0 }
				},
				'& .editor-quote': {
					m: 0,
					ml: 2.5,
					pl: 2,
					fontSize: 15,
					color: themeMode === 'dark' ? grey[300] : grey[700],
					borderLeftColor: themeMode === 'dark' ? grey[300] : grey[700],
					borderLeftWidth: 4,
					borderLeftStyle: 'solid'
				},
				'& .editor-heading-h1': {
					m: 0,
					mb: '0.35em',
					fontSize: '3rem',
					fontWeight: 700,
					lineHeight: 1.23,
					letterSpacing: '-0.01562em',
					color: 'primary.light'
				},
				'& .editor-heading-h2': {
					m: 0,
					mb: '0.35em',
					fontSize: '3.75rem',
					fontWeight: 300,
					lineHeight: 1.2,
					letterSpacing: '-0.00833em',
					color: 'primary.light'
				},
				'& .editor-heading-h3': {
					m: 0,
					mb: '0.35em',
					fontSize: '1.5rem',
					fontWeight: 700,
					lineHeight: 1.2,
					color: themeMode === 'dark' ? '#014178' : '#5B41FF'
				},
				'& .editor-heading-h4': {
					m: 0,
					mb: '0.35em',
					fontSize: '2.125rem',
					fontWeight: 400,
					lineHeight: 1.235,
					letterSpacing: '0.00735em',
					color: themeMode === 'dark' ? 'secondary.light' : '#014178'
				},
				'& .editor-heading-h5': {
					m: 0,
					mb: '0.35em',
					fontSize: '1.5rem',
					fontWeight: 400,
					lineHeight: 1.334,
					color: themeMode === 'dark' ? 'secondary.light' : '#014178'
				},
				'& .editor-heading-h6': {
					m: 0,
					mb: '0.35em',
					fontSize: '1.25rem',
					fontWeight: 500,
					lineHeight: 1.6,
					letterSpacing: '0.0075em',
					color: themeMode === 'dark' ? 'secondary.light' : '#014178'
				},
				'& .editor-nested-listitem': { listStyleType: 'none' },
				'& .editor-list-ol': {
					m: 0,
					ml: 2,
					p: 0
				},
				'& .editor-list-ul': {
					m: 0,
					ml: 2,
					p: 0
				},
				'& .editor-listitem': {
					my: 1,
					mx: 4
				},
				'& .editor-image': {
					cursor: 'default',
					userSelect: 'none',
					display: 'inline-block',
					position: 'relative'
				},
				'& .editor-link': {
					color: blue[500],
					textDecoration: 'none'
				},
				'& .editor-text-bold': {
					fontWeight: 'bold',
					color: themeMode === 'dark' ? 'secondary.light' : '#014178'
				},
				'& .editor-text-italic': { fontStyle: 'italic' },
				'& .editor-text-overflowed': {},
				'& .editor-text-hashtag': {},
				'& .editor-text-underline': { textDecoration: 'underline' },
				'& .editor-text-strikethrough': { textDecoration: 'line-through' },
				'& .editor-text-underlineStrikethrough': { textDecoration: 'underline line-through' },
				'& .editor-text-code': {
					py: 0.25,
					px: 1,
					fontFamily: 'Menlo, Consolas, Monaco, monospace',
					fontSize: '94%',
					bgcolor: blueGrey[50]
				},
				'& .editor-code': {
					display: 'block',
					position: 'relative',
					my: 1,
					mx: 0,
					p: 1,
					pl: 6.5,
					fontFamily: 'Menlo, Consolas, Monaco, monospace',
					fontSize: 13,
					lineHeight: 1.53,
					bgcolor: grey[50],
					overflowX: 'auto',
					tabSize: 2,
					'&::before': {
						content: 'attr(data-gutter)',
						minWidth: 25,
						height: 1,
						position: 'absolute',
						left: 0,
						top: 0,
						p: 1,
						whiteSpace: 'pre-wrap',
						textAlign: 'right',
						color: grey[500],
						bgcolor: grey[100],
						borderRight: `1px solid ${grey[200]}`
					},
					'&::after': {
						content: 'attr(data-highlight-language)',
						height: 1,
						position: 'absolute',
						top: 0,
						right: 3,
						p: 0.375,
						fontSize: 10,
						textTransform: 'uppercase',
						color: alpha(theme.palette.common.black, 0.5)
					}
				},
				'& .editor-tokenAttr': { color: lightBlue[800] },
				'& .editor-tokenProperty': { color: pink[900] },
				'& .editor-tokenSelector': { color: lime[700] },
				'& .editor-tokenComment': { color: blueGrey[800] },
				'& .editor-tokenFunction': { color: pink[400] },
				'& .editor-tokenOperator': { color: brown[500] },
				'& .editor-tokenVariable': { color: amber[700] },
				'& .editor-tokenPunctuation': { color: grey[500] }
			}}
		>
			<TransAtom>{children}</TransAtom>
		</Box>
	)
}

export default TextAtom

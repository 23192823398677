'use client'

import { useState, useCallback, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { useTheme, alpha } from '@mui/material/styles'
import { Grid, Typography, Divider, FormControl, FormLabel, FormHelperText } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'

// Types
import type { OptionItem } from '@/types'
import type { APIDictionaryTopicReadData } from '@/types/api/dictionary-topic/read'
import type { APIDictionaryDialectReadData } from '@/types/api/dictionary-dialect/read'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

// Components
const FormMolecule = dynamic(() => import('@/components/molecules/forms/base'))
const TlichoButtonsMolecule = dynamic(() => import('@/components/molecules/tlicho-buttons'))
const TextFieldAtom = dynamic(() => import('@/components/atoms/fields/text'))
const SelectAtom = dynamic(() => import('@/components/atoms/fields/select'))
const GeneralButtonAtom = dynamic(() => import('@/components/atoms/buttons/general'))

const SuggestionFormMolecule = () => {
	// Variables
	const { t } = useTranslation()
	const theme = useTheme()
	const [loadingTopics, setLoadingTopics] = useState<boolean>(false)
	const [loadingDialects, setLoadingDialects] = useState<boolean>(false)
	const [loadingWordTypes, setLoadingWordTypes] = useState<boolean>(false)
	const [loadingGrammers, setLoadingGrammers] = useState<boolean>(false)
	const [topics, setTopics] = useState<OptionItem[]>([])
	const [dialects, setDialects] = useState<OptionItem[]>([])
	const [wordTypes, setWordTypes] = useState<OptionItem[]>([])
	const [grammers, setGrammers] = useState<OptionItem[]>([])

	// Form
	const { control, setValue } = useForm({
		defaultValues: {
			term: '',
			newTerm: '',
			dilect: 'placeholder',
			topic: 'placeholder',
			type: 'placeholder',
			grammer: 'placeholder'
		}
	})

	const values = useWatch({ control })

	// Callbacks
	const addLetter = (letter: string) => {
		let output = values?.newTerm ?? ''
		output += letter

		setValue('newTerm', output)
	}

	const readDialects = useCallback(async () => {
		setLoadingDialects(true)

		// Result
		const response = await fetch(`/api/dictionary-dialect/read?limit=1000&skip=0`)
		const result: APIDictionaryDialectReadData = await response.json()

		setLoadingDialects(false)

		if (result.status && result.data && result.data.items)
			setDialects(
				result.data.items.map(item => {
					return {
						label: item.title,
						value: item.alias
					}
				})
			)
	}, [])

	const readTopics = useCallback(async () => {
		setLoadingTopics(true)

		// Result
		const response = await fetch(`/api/dictionary-topic/read?limit=1000&skip=0`)
		const result: APIDictionaryTopicReadData = await response.json()

		setLoadingTopics(false)

		if (result.status && result.data && result.data.items)
			setTopics(
				result.data.items.map(item => {
					return {
						label: item.title,
						value: item.alias
					}
				})
			)
	}, [])

	const readWordTypes = useCallback(async () => {
		setLoadingWordTypes(true)

		// Result
		const response = await fetch(`/api/dictionary-word-type/read?limit=1000&skip=0`)
		const result: APIDictionaryTopicReadData = await response.json()

		setLoadingWordTypes(false)

		if (result.status && result.data && result.data.items)
			setWordTypes(
				result.data.items.map(item => {
					return {
						label: item.title,
						value: item.alias
					}
				})
			)
	}, [])

	const readGrammers = useCallback(async () => {
		setLoadingGrammers(true)

		// Result
		const response = await fetch(`/api/dictionary-grammer/read?limit=1000&skip=0`)
		const result: APIDictionaryTopicReadData = await response.json()

		setLoadingGrammers(false)

		if (result.status && result.data && result.data.items)
			setGrammers(
				result.data.items.map(item => {
					return {
						label: item.title,
						value: item.alias
					}
				})
			)
	}, [])

	useEffect(() => {
		if (!dialects.length) readDialects()
		if (!topics.length) readTopics()
		if (!wordTypes.length) readWordTypes()
		if (!grammers.length) readGrammers()
	}, [dialects, topics, wordTypes, grammers, readDialects, readTopics, readWordTypes, readGrammers])

	return (
		<FormMolecule>
			<Grid item xs={12} px={{ smmd: 2 }}>
				<FormControl fullWidth>
					<FormLabel>{t('form:label.existingEntry')}</FormLabel>
					<TextFieldAtom name="term" type="text" placeholder="form:label.term" control={control} />
				</FormControl>
			</Grid>

			<Grid item xs={12} mt={{ xs: 2, smmd: 4 }} px={2}>
				<Grid container justifyContent="center" alignItems="center">
					<GeneralButtonAtom size="fixed" color="#0f054c" type="submit">
						{t('form:button.search')}
					</GeneralButtonAtom>
				</Grid>
			</Grid>

			<Grid item xs={12} mt={3} p={2}>
				<Grid container px={{ smmd: 4 }} border={{ md: `1px solid ${theme.palette.primary.dark}` }} borderRadius={1}>
					<Grid item xs={12} mt={2}>
						<Typography variant="subtitle2" fontWeight={700} fontSize={14} textAlign="center">
							{t('form:button.add')}
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<TlichoButtonsMolecule color="default" onClick={value => addLetter(value)} />
					</Grid>

					<Grid item xs={12} mt={2}>
						<Typography variant="body2" color="#706f74">
							{t('form:message.copyPaste')}
						</Typography>
					</Grid>

					<Grid item xs={12} mt={2}>
						<FormControl fullWidth>
							<FormLabel>{t('form:label.wordAdd')}</FormLabel>
							<TextFieldAtom name="newTerm" type="text" placeholder="form:label.term" control={control} />
						</FormControl>
					</Grid>

					<Grid item xs={12} mt={{ xs: 2, smmd: 4 }}>
						<Divider />
					</Grid>

					<Grid item xs={12} mt={{ smmd: 2 }}>
						<Grid container justifyContent="space-between">
							<Grid item xs={12} smmd={5.5} mt={{ smmd: 2 }}>
								<SelectAtom
									control={control}
									name="dilect"
									label="form:label.dilect"
									helper="form:helper.dilect"
									placeholder="form:label.search"
									items={dialects}
								/>
							</Grid>

							<Grid item xs={12} smmd={5.5} mt={{ smmd: 2 }}>
								<SelectAtom control={control} name="topic" label="form:label.topic" helper="form:helper.topic" placeholder="form:label.search" items={topics} />
							</Grid>

							<Grid item xs={12} smmd={5.5} mt={{ smmd: 2 }}>
								<SelectAtom control={control} name="type" label="form:label.type" helper="form:helper.type" placeholder="form:label.search" items={wordTypes} />
							</Grid>

							<Grid item xs={12} smmd={5.5} mt={{ smmd: 2 }}>
								<SelectAtom
									control={control}
									name="grammer"
									label="form:label.grammer"
									helper="form:helper.grammer"
									placeholder="form:label.search"
									items={grammers}
								/>
							</Grid>

							<Grid item xs={12} mt={{ smmd: 2 }}>
								<FormControl fullWidth>
									<TextFieldAtom control={control} name="definition" type="text" placeholder="form:label.definition" multiline />
									<FormHelperText>{t('form:helper.definition')}</FormHelperText>
								</FormControl>
							</Grid>

							<Grid item xs={12} mt={{ xs: 2, smmd: 4 }}>
								<Grid container px={2} border={`1px solid ${alpha(theme.palette.primary.dark, 0.2)}`} borderRadius={1}>
									<Grid item xs={12} mt={2}>
										<FormLabel>{t('form:label.wordUsed')}</FormLabel>
										<TextFieldAtom name="sentence1" type="text" placeholder="form:label.sentence1" control={control} />
									</Grid>

									<Grid item xs={12} my={2}>
										<FormLabel>{t('form:label.translationOfContext')}</FormLabel>
										<TextFieldAtom name="context1" type="text" placeholder="form:label.context1" control={control} />
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} mt={{ xs: 2, smmd: 4 }}>
								<Grid container px={2} border={`1px solid ${alpha(theme.palette.primary.dark, 0.2)}`} borderRadius={1}>
									<Grid item xs={12} mt={2}>
										<FormLabel>{t('form:label.wordUsed')}</FormLabel>
										<TextFieldAtom name="sentence2" type="text" placeholder="form:label.sentence2" control={control} />
									</Grid>

									<Grid item xs={12} my={2}>
										<FormLabel>{t('form:label.translationOfContext')}</FormLabel>
										<TextFieldAtom name="context2" type="text" placeholder="form:label.context2" control={control} />
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} mt={2} mb={{ xs: 2, smmd: 4 }}>
								<Grid container justifyContent="center" alignItems="center">
									<GeneralButtonAtom size="fixed" color="#0f054c" type="submit">
										{t('form:button.submit')}
									</GeneralButtonAtom>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</FormMolecule>
	)
}

export default SuggestionFormMolecule

'use client'

import dynamic from 'next/dynamic'
import { useState } from 'react'
import { useColorScheme, useTheme, alpha } from '@mui/material/styles'
import { useMediaQuery, Grid, Typography } from '@mui/material'

// Types
import type { CreditProps, BoxProps } from '@/types/components/molecules/box'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

// Componenets
const DialogMolecule = dynamic(() => import('@/components/molecules/dialog'))
const TextAtom = dynamic(() => import('@/components/atoms/article/text'))
const GeneralButtonAtom = dynamic(() => import('@/components/atoms/buttons/general'))

const CreditTitle = (props: CreditProps) => (
	<Typography component="p" fontSize={16} fontWeight={700} lineHeight={1.25} textAlign="center" color="primary.dark">
		{props.children}
	</Typography>
)

const CreditName = (props: CreditProps) => (
	<Typography component="p" fontWeight={400} lineHeight={1.22} textAlign="center">
		{props.children}
	</Typography>
)

const BoxMolecule = (props: BoxProps) => {
	// Props
	const { direction, description, maxWidth } = props

	// Variables
	const { t } = useTranslation()
	const theme = useTheme()
	const [open, setOpen] = useState(false)
	let image: string = ''
	let finalDescription: string = description || ''
	const greaterThanMedium = useMediaQuery(theme.breakpoints.up('md'))

	// Mode
	const { mode } = useColorScheme()
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
	const themeMode = mode == 'system' ? (prefersDarkMode ? 'dark' : 'light') : mode

	// Find
	const result = /<p[^>]+>(<img[^>]+>)<\/p>/g.exec(finalDescription)

	if (result && result.length > 1) {
		finalDescription = finalDescription.replace(result[0], '')
		image = result[1]
	}

	return (
		<Grid container direction="column" justifyContent="center" alignItems="flex-end" p={6} pt={8} bgcolor={alpha('#d9d9d9', 0.2)} borderRadius={2}>
			<Grid item xs={12}>
				<TextAtom>{finalDescription}</TextAtom>
			</Grid>

			<Grid item>
				<GeneralButtonAtom
					onClick={() => setOpen(true)}
					color={theme.palette.mode === 'dark' ? '#5b41ff' : 'secondary.contrastText'}
					size={greaterThanMedium ? 'fixed' : undefined}
				>
					{t('form:button.creadits')}
				</GeneralButtonAtom>
			</Grid>

			<DialogMolecule title="common:title.credits" open={open} setOpen={setOpen}>
				<Grid container direction="column" spacing={2}>
					<Grid item>
						<CreditTitle>{t('common:title.programmer')}</CreditTitle>
						<CreditName>{t('common:credit.merhnoushZeidabadi')}</CreditName>
						<CreditName>{t('common:credit.fatemeMaklavani')}</CreditName>
						<CreditName>{t('common:credit.hossseinMaklavani')}</CreditName>
						<CreditName>{t('common:credit.fatemeRostami')}</CreditName>
						<CreditName>{t('common:credit.farzadHamedi')}</CreditName>
						<CreditName>{t('common:credit.ataallahMohammadi')}</CreditName>
						<CreditName>{t('common:credit.christopherCoey')}</CreditName>
					</Grid>

					<Grid item>
						<CreditTitle>{t('common:title.development')}</CreditTitle>
						<CreditName>{t('common:credit.tammySteinwand')}</CreditName>
						<CreditName>{t('common:credit.tyannaSteinwand')}</CreditName>
						<CreditName>{t('common:credit.giselleMarion')}</CreditName>
						<CreditName>{t('common:credit.kellyRyder')}</CreditName>
						<CreditName>{t('common:credit.jacynthiaRabesca')}</CreditName>
						<CreditName>{t('common:credit.rosieBenning')}</CreditName>
						<CreditName>{t('common:credit.georginaFranki')}</CreditName>
						<CreditName>{t('common:credit.danielDacanay')}</CreditName>
					</Grid>

					<Grid item>
						<CreditTitle>{t('common:title.voice')}</CreditTitle>
						<CreditName>{t('common:credit.marieLouise')}</CreditName>
						<CreditName>{t('common:credit.rosaMantlaand')}</CreditName>
						<CreditName>{t('common:credit.georgeBailey')}</CreditName>
						<CreditName>{t('common:credit.josieBishop')}</CreditName>
						<CreditName>{t('common:credit.anitaDaniels')}</CreditName>
						<CreditName>{t('common:credit.leonaLafferty')}</CreditName>
						<CreditName>{t('common:credit.masonMantla')}</CreditName>
						<CreditName>{t('common:credit.maryRichardson')}</CreditName>
					</Grid>

					<Grid item>
						<CreditTitle>{t('common:title.research')}</CreditTitle>
						<CreditName>{t('common:credit.adarAnisman')}</CreditName>
						<CreditName>{t('common:credit.angelHall')}</CreditName>
						<CreditName>{t('common:credit.sooyounHam')}</CreditName>
						<CreditName>{t('common:credit.alianaParker')}</CreditName>
						<CreditName>{t('common:credit.matthewRichards')}</CreditName>
						<CreditName>{t('common:credit.nicholasWelch')}</CreditName>
					</Grid>

					<Grid item>
						<CreditTitle>{t('common:title.funders')}</CreditTitle>
						<CreditName>{t('common:credit.dogribDivisional')}</CreditName>
						<CreditName>{t('common:credit.tlichoCommunity')}</CreditName>
						<CreditName>{t('common:credit.tlichoGovernment')}</CreditName>
						<CreditName>{t('common:credit.socialCouncil')}</CreditName>
						<CreditName>{t('common:credit.victoriaVicePresident')}</CreditName>
						<CreditName>{t('common:credit.victoriaLinguistics')}</CreditName>
					</Grid>

					<Grid item>
						<CreditTitle>{t('common:title.thanks')}</CreditTitle>
						<CreditName>{t('common:credit.victoriaServices')}</CreditName>
						<CreditName>{t('common:credit.victoriaLinguistics')}</CreditName>
						<CreditName>{t('common:credit.tracyBeaulieu')}</CreditName>
						<CreditName>{t('common:credit.lucyLafferty')}</CreditName>
						<CreditName>{t('common:credit.giseleMarion')}</CreditName>
						<CreditName>{t('common:credit.jimMartin')}</CreditName>
						<CreditName>{t('common:credit.jimStauffer')}</CreditName>
						<CreditName>{t('common:credit.caroleTinqui')}</CreditName>
					</Grid>
				</Grid>
			</DialogMolecule>
		</Grid>
	)
}

export default BoxMolecule

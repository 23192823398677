'use client'

import dynamic from 'next/dynamic'
import { Grid } from '@mui/material'
import { useForm } from 'react-hook-form'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

// Components
const FormMolecule = dynamic(() => import('@/components/molecules/forms/base'))
const TextFieldAtom = dynamic(() => import('@/components/atoms/fields/text'))
const GeneralButtonAtom = dynamic(() => import('@/components/atoms/buttons/general'))

const ContactFormMolecule = () => {
	// Form
	const { control } = useForm()

	// Variables
	const { t } = useTranslation()

	return (
		<FormMolecule title="form:label.contact">
			<Grid item xs={12}>
				<TextFieldAtom name="name" type="text" placeholder="form:label.name" control={control} />
			</Grid>

			<Grid item xs={12}>
				<TextFieldAtom name="email" type="email" placeholder="form:label.email" control={control} />
			</Grid>

			<Grid item xs={12}>
				<TextFieldAtom name="title" type="text" placeholder="form:label.title" control={control} />
			</Grid>

			<Grid item xs={12}>
				<TextFieldAtom name="message" type="text" placeholder="form:label.message" multiline={true} minRows={5} control={control} />
			</Grid>

			<Grid item xs={12} display="flex" justifyContent="center">
				<GeneralButtonAtom size="fixed" color="#0f054c" type="submit">
					{t('form:button.send')}
				</GeneralButtonAtom>
			</Grid>
		</FormMolecule>
	)
}

export default ContactFormMolecule

'use client'

import dynamic from 'next/dynamic'
import { useColorScheme, alpha } from '@mui/material/styles'
import { useMediaQuery, Container, Grid } from '@mui/material'
import { grey } from '@mui/material/colors'

// Types
import type { ContactProps } from '@/types/components/atoms/contact'

// Componenets
const TransAtom = dynamic(() => import('@/components/atoms/article/trans'))

const ContactAtom = (props: ContactProps) => {
	// Props
	const { description, maxWidth } = props

	// Mode
	const { mode } = useColorScheme()
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
	const themeMode = mode == 'system' ? (prefersDarkMode ? 'dark' : 'light') : mode

	return (
		<Container maxWidth={maxWidth} sx={{ position: 'relative', mt: 5, mb: 3, zIndex: 3 }}>
			<Grid
				container
				flexDirection="column"
				py={6}
				spacing={{ smmd: 2 }}
				sx={{
					width: { xs: 1, smmd: 'calc(70% + 16px)' },
					position: 'relative',
					px: { xs: 4, smmd: 8 },
					'&::before': {
						content: '""',
						width: 1,
						height: 1,
						position: 'absolute',
						top: 0,
						left: 0,
						bgcolor: themeMode === 'dark' ? alpha('common.black', 0.8) : alpha('common.white', 0.8),
						borderRadius: 1,
						boxShadow: `0px 12px 40px 0px ${alpha(grey[900], 0.04)}`,
						zIndex: -1
					},
					'& a': {
						color: themeMode === 'dark' ? 'common.white' : 'common.black',
						transition: 'all .3s ease',
						'&:hover': { color: 'primary.light' }
					}
				}}
			>
				<TransAtom>{description}</TransAtom>
			</Grid>
		</Container>
	)
}

export default ContactAtom
